<template>
    <div>
        <v-container fluid>
            <h3>Widgets</h3>
            <v-btn text :to="{ name: 'manage-websites', params: { domainname: siteUrl }}" outlined
                   class="btn btn-lg btn-secondary-main" style="background:transparent;">
                <i class="fas fa-arrow-left"></i> Back
            </v-btn>
            <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
            <i class="fa fa-globe"></i>
            {{siteUrl}}
        </span>

            <v-app class="mt-2">
              <div class="row" v-if="loading">
                <div class="col-md-8 mt-2">
                  <v-skeleton-loader class="mx-auto" type=" article,list-item"></v-skeleton-loader>
                </div>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="8">
                    <v-card v-if="marketplace_widget">
                      <v-card-title primary-title class="justify-content-between">
                        {{marketplace_widget.title}}
                        <div class="text-right">
                          <v-btn outlined v-text="widget.is_active==true? 'Deactivate':'Activate' "
                                 @click.prevent="createOrUpdate()"></v-btn>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        {{marketplace_widget.description}}
                      </v-card-text>

                    </v-card>
                  </v-col>
                  <v-col cols="4" v-if="widget.is_active">
                    <v-card>
                      <v-card-title primary-title class="justify-content-between">
                        Facebook Chat Widget Settings
                        <div class="text-right">
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                                label="Page Id"
                                outlined
                                v-model="fb_chat_widget.page_id"
                                dense
                            ></v-text-field>
                            <span class="text-danger" v-if="$v.fb_chat_widget.page_id.$error">{{validationMessage.page_id}}</span>


                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                label="Theme Color"
                                outlined
                                v-model="fb_chat_widget.theme_color"
                                dense
                            ></v-text-field>
                            <span class="text-danger" v-if="$v.fb_chat_widget.theme_color.$error">{{validationMessage.theme_color}}</span>

                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                label="Logged In Greeting Message"
                                outlined
                                v-model="fb_chat_widget.login_greeting"
                                dense
                            ></v-text-field>
                            <span class="text-danger" v-if="$v.fb_chat_widget.login_greeting.$error">{{validationMessage.login_greeting}}</span>

                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                label="Logged Out Greeting Message"
                                outlined
                                v-model="fb_chat_widget.logout_greeting"
                                dense
                            ></v-text-field>
                            <span class="text-danger" v-if="$v.fb_chat_widget.logout_greeting.$error">{{validationMessage.logout_greeting}}</span>

                          </v-col>

                        </v-row>
                      </v-card-text>
                      <v-card-actions class="text-right">
                        <v-btn  :loading="loading" outlined dense @click="update" class="text-right btn btn-primary">Save
                        </v-btn>
                      </v-card-actions>

                    </v-card>
                  </v-col>
                  <v-col cols="4" v-else>
                    <v-card>
                      <v-card-title primary-title class="justify-content-between">
                       Widget not active
                        <div class="text-right">
                        </div>
                      </v-card-title>
                      <v-card-text>
                        Please activate this plugin to configure settings for this plugin.
                      </v-card-text>

                    </v-card>
                  </v-col>

                </v-row>
              </div>

            </v-app>

        </v-container>
    </div>
</template>

<script>
    import Widget from "@/services/Websites/WebsiteWebsiteWigdetService";

    const WidgetService = new Widget();
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: "vue-fb-chats",
        components: {
            KTPortlet
        },
        validations: {
            fb_chat_widget: {
                page_id: {
                    required
                },
                theme_color: {
                    required
                },
                login_greeting: {
                    required
                }
                ,
                logout_greeting: {
                    required
                }
            }
        },

        data: () => ({
            loading: true,
            edit: false,
            marketplace_widget: null,
            widget: {
                id: null,
                website_id: null,
                widget_id: null,
                widget_values: null,
                activated_on: null,
                is_active: false,

            },
            fb_chat_widget: {
                page_id: null,
                theme_color: null,
                login_greeting: null,
                logout_greeting: null,
            },
            validationMessage: {
                page_id: "Page ID is required field",
                theme_color: "Theme_color is required field",
                login_greeting: "Login Greeting is required field",
                logout_greeting: "Logout Greeting is required field",
            },

        }),
        mounted() {
            this.getMarketPlaceWidget();
        },
        methods: {
            getMarketPlaceWidget() {
                WidgetService.getMarketplaceWidgetsByIdentifier('fb_chat').then((res) => {
                    this.marketplace_widget = res.data.marketplace_widget;
                    this.getWebsiteWidget();
                    this.loading=false;
                })
            },
            getWebsiteWidget() {
                WidgetService.getByWidgetId(this.siteUrl, this.marketplace_widget.id).then((res) => {
                    if (res) {
                        this.widget = res.data.widget;
                        if (this.widget.widget_values)
                            this.fb_chat_widget = JSON.parse(this.widget.widget_values);
                    }

                  this.loading=false;

                })
            },
            createOrUpdate() {
                if (this.widget.id == null) this.create();
                else if (this.widget.id != null && !this.widget.is_active) {
                    this.enableWidget()
                } else this.disableWidget();
            },

            create: function () {
                this.widget.widget_id = this.marketplace_widget.id;
                this.widget.is_active = true;
                WidgetService.create(this.siteUrl, this.widget)
                    .then(response => {
                        this.$snotify.success("Facebook Chat Enabled successfully");
                        this.getMarketPlaceWidget();
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                    });
            },
            update: function () {
                this.$v.fb_chat_widget.$touch();
                if (this.$v.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    this.widget.widget_values = JSON.stringify(this.fb_chat_widget);
                    this.loading = true;
                    WidgetService.update(this.siteUrl, this.widget.id, this.widget)
                        .then(response => {
                            this.$snotify.success("Widget Updated  successfully");
                            this.resetForm();
                        })
                        .catch(error => {
                            this.errors = error.response.data.errors;
                        });
                }
            },
            disableWidget: function () {
                this.widget.is_active = false;
                this.loading = true;
                WidgetService.update(this.siteUrl, this.widget.id, this.widget)
                    .then(response => {
                        this.$snotify.success("Widget Updated  successfully");
                        this.resetForm();
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                    });
            },
            enableWidget: function () {
                this.widget.is_active = true;
                this.loading = true;
                WidgetService.update(this.siteUrl, this.widget.id, this.widget)
                    .then(response => {
                        this.$snotify.success("Widget Updated  successfully");
                        this.resetForm();
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                    });
            },

            _delete: function (id) {
                this.$root
                    .$confirm("Delete", "Are you sure?", {
                        color: "red"
                    })
                    .then(confirm => {
                        if (confirm) {
                            WidgetService.delete(id)
                                .then(response => {
                                    this.$snotify.success("currency deleted successfully");
                                    this.resetForm();
                                })
                                .catch(error => {
                                    // console.log(error);
                                });
                        }
                    });
            },
            resetForm() {
                this.loading = false;
                this.widget = {
                    id: null,
                    website_id: null,
                    widget_id: null,
                    widget_values: null,
                    activated_on: null,
                    is_active: false,
                };
                this.$v.$reset();
                this.getMarketPlaceWidget();
            }
        },
        computed: {

            siteUrl() {
                return this.$route.params.domainname;
            },

        }
    };
</script>
